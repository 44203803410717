<template>
  <el-dialog
    title="标记"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="分组" prop="group">
          <el-select
            style="width: 100%;"
            v-model="form.group"
            placeholder="请选择分组"
            filterable
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitFun">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  group_all_Api,
  group_facebook_Api,
  group_twitter_Api,
  group_youtube_Api
} from '@/api/group'

export default {
  data() {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        group: [
          {
            required: true,
            message: '请选择分组',
            trigger: 'change'
          }
        ]
      },
      options: [],
      accountType: ''
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    initFun(id, type, group) {
      this.accountType = type
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.form = { id: id }
      if (group) {
        this.form.group = group
      }
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    submitFun() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.accountType === 'facebook') {
            group_facebook_Api(this.form.id, { group: this.form.group }).then(
              () => {
                this.dialogVisible = false
                this.$emit('updateFun')
              }
            )
          } else if (this.accountType === 'twitter') {
            group_twitter_Api(this.form.id, { group: this.form.group }).then(
              () => {
                this.dialogVisible = false
                this.$emit('updateFun')
              }
            )
          } else if (this.accountType === 'youtube') {
            group_youtube_Api(this.form.id, { group: this.form.group }).then(
              () => {
                this.dialogVisible = false
                this.$emit('updateFun')
              }
            )
          }
        }
      })
    },
    getOptions() {
      group_all_Api().then(data => {
        this.options = data
      })
    }
  }
}
</script>
