<template>
  <div>
    <div class="material-page">
      <div class="btn-left">
        <el-button type="primary" size="mini" @click="openDialog">
          选择
        </el-button>
        <el-button v-if="sureData.id" size="mini" @click="cancellation">
          取消选择
        </el-button>
        <div>
          <router-link :to="'/material/materialUpload'">
            <el-link type="primary">去上传素材</el-link>
          </router-link>

          <router-link :to="'/material/myMaterial'" style="margin-left:20px">
            <el-link type="primary">已上传的素材</el-link>
          </router-link>
        </div>
      </div>
      <div class="btn-right">
        <img :src="sureData.thumbnail_url" alt="" />

        <span>
          {{ sureData.name }}
        </span>
      </div>
    </div>
    <!--  -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
      append-to-body
    >
      <div class="material-search">
        <div>
          <el-form ref="form" :model="searchForm" :inline="true">
            <el-form-item>
              <span style="font-size: 20px;margin-right: 10px;">素材</span>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="searchForm.name"
                placeholder="请输入文件名"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="searchForm.search"
                placeholder="请输入关键词"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchFun">查询</el-button>
              <el-button @click="reset">重置</el-button>
              <span
                style="margin-left: 10px;font-size: 12px;color: rgb(156, 156, 158);"
              >
                点击方格选择图片
              </span>
            </el-form-item>
          </el-form>
        </div>
        <div class="material-box" v-if="list.length > 0" v-loading="loading">
          <div
            v-for="(item, i) in list"
            :key="i"
            @click.stop="selectImgFun(item)"
            :class="selectData.id == item.id ? 'select-img' : ''"
          >
            <el-button
              class="preview"
              type="text"
              @click.stop="previewFun(item)"
            >
              预览
            </el-button>
            <img :src="item.thumbnail_url" alt="" />
            <div class="name">
              {{ item.name }}
            </div>
            <div class="remark">
              {{ item.remark }}
            </div>
          </div>
        </div>
        <div v-else class="material-box-none">
          暂无数据~
        </div>
        <div class="material-foot">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="current"
            :pager-count="5"
            layout="total, prev, pager, next,jumper"
            :total="total"
          ></el-pagination>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false" size="small">
              取 消
            </el-button>
            <el-button type="primary" size="small" @click="sureFun">
              确 定
            </el-button>
          </span>
        </div>
      </div>
    </el-dialog>
    <!-- 图片 -->
    <el-dialog
      :visible.sync="imgDialogVisible"
      width="50%"
      :before-close="imgHandleClose"
      append-to-body
    >
      <img
        v-if="selectData.mime_type && selectData.mime_type.startsWith('image')"
        class="only-img"
        :src="selectData.thumbnail_url"
        alt=""
      />
      <video-player
        v-if="selectData.mime_type && selectData.mime_type.startsWith('video')"
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      ></video-player>
      <div style="padding: 5px 0;"></div>
    </el-dialog>
  </div>
</template>
<script>
import { getMaterialList, getMaterial } from '@/api/material'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
export default {
  components: { videoPlayer },
  data() {
    return {
      dialogVisible: false,
      searchForm: {},
      current: 1,
      limit: 10,
      total: 0,
      list: [],
      selectData: {},
      sureData: {},
      loading: false,
      //
      imgDialogVisible: false,
      //
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 类型
            src: '' // url地址
          }
        ],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
    }
  },
  created() {},
  methods: {
    searchFun() {
      this.current = 1
      this.list = []
      this.selectData = {}
      this.sureData = {}
      this.getData()
    },
    reset() {
      this.current = 1
      this.list = []
      this.searchForm = {}
      this.getData()
    },
    getData() {
      let obj = Object.assign(
        {
          pageIndex: this.current,
          pageSize: this.limit,
          videoStyle: 'video/snapshot,t_0,f_jpg,h_200,m_fast',
          imageStyle: 'image/resize,m_fixed,h_200',
          category: 1
        },
        this.searchForm
      )
      this.loading = true
      getMaterialList(obj)
        .then(res => {
          this.list = res.items
          this.total = res.all_count
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    //
    cancellation() {
      this.selectData = {}
      this.sureData = {}
      this.$emit('getChildMaterial', {})
    },
    openDialog() {
      this.dialogVisible = true
      this.current = 1
      this.list = []
      this.searchForm = {}
      this.getData()
    },
    handleClose() {
      this.dialogVisible = false
    },
    //
    selectImgFun(row) {
      this.selectData = Object.assign({}, row)
    },
    previewFun(row) {
      getMaterial(row.id, {
        videoStyle: 'video/snapshot,t_0,f_jpg,h_600,m_fast',
        imageStyle: 'image/resize,m_fixed,h_600'
      }).then(res => {
        this.selectData = Object.assign({}, res)
        if (row.mime_type.startsWith('video')) {
          this.playerOptions.sources[0].src = row.signature_url
        }
        this.imgDialogVisible = true
      })

      //
      // this.selectData = Object.assign({}, row)
      // if (row.mime_type.indexOf('video') > -1) {
      //   this.playerOptions.sources[0].src = row.thumbnail_url
      // }
      // this.imgDialogVisible = true
    },
    // 分页
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.current = val
      this.getData()
    },
    // 确定
    sureFun() {
      if (!this.selectData.id) {
        return this.$message.warning('请选择素材！')
      }
      this.sureData = Object.assign({}, this.selectData)
      this.$emit('getChildMaterial', this.sureData)
      this.dialogVisible = false
    },
    //
    imgHandleClose() {
      this.imgDialogVisible = false
      this.playerOptions.sources[0].src = ''
    }
  }
}
</script>
<style scoped lang="scss">
.material-page {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .btn-right {
    width: 400px;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.material-box {
  width: 100%;
}
.material-box {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  > div {
    margin-right: 10px;
    box-sizing: border-box;
    padding: 10px;
    background-color: rgb(243, 241, 237);
    width: calc(100% / 5 - 10px);
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;

    img {
      width: 100%;
      height: 15vh;
      object-fit: cover;
    }
    .remark,
    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
    .remark {
      margin-top: 10px;
    }
    &:hover {
      img {
        transform: scale(0.98);
        transition: 0.8s;
      }
    }
  }
  .select-img {
    background: #017c16;
    .remark,
    .name {
      color: white;
    }
  }
}
.material-box-none {
  text-align: center;
  line-height: 50px;
  color: #979797;
}
.only-img {
  width: 100%;
  height: auto;
}
.material-foot {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
::v-deep .el-dialog__body {
  padding: 0 20px;
}
::v-deep .el-pagination {
  margin-top: 0px;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
::v-deep .el-dialog__headerbtn {
  top: 10px;
}
</style>
