// var startTime = getStartTime()
export const pickerOptions = {
  shortcuts: [
    {
      text: '今天',
      onClick(picker) {
        picker.$emit('pick', new Date())
      }
    },
    {
      text: '明天',
      onClick(picker) {
        const date = new Date()
        date.setTime(date.getTime() + 3600 * 1000 * 24)
        picker.$emit('pick', date)
      }
    },
    {
      text: '一周后',
      onClick(picker) {
        const date = new Date()
        date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
        picker.$emit('pick', date)
      }
    }
  ]
  // disabledDate(time) {
  //   return time.getTime() < new Date().getTime() - 86400000
  // },
  // start: '08:30',
  // step: '00:15',
  // end: '18:30'
  // selectableRange: '18:30:00 - 20:30:00'
  // selectableRange: `${startTime} - 23:59:59`
  // selectableRange(time) {
  //   return time.getTime() < new Date().getTime()
  // }
}

export function getAfterTime(second) {
  const nowDate = new Date()
  const nowTimestamp = nowDate.getTime()
  const afterTime = new Date(nowTimestamp + second * 1000)
  // console.log('10分钟后：', afterTime)
  // return afterTime
  const transferTime = chatTime(afterTime)
  // console.log('10分钟后：', transferTime)
  return transferTime
}
// 时间函数转化
function chatTime(val) {
  const date = new Date(val)
  const year = date.getFullYear()
  const month =
    date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const min =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const sec =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  // 返回格式为 yyyy-xx-dd hh:mm:ss
  return `${year}-${month}-${day} ${hours}:${min}:${sec}`
}
// 设置时间之前的不能选
// function getStartTime() {
//   // const nowDate = new Date()
//   const afterTime = getAfterTime(10 * 60)
//   const transferNowTime = chatTime(afterTime)
//   const finTransferTime = transferNowTime.slice(11)
//   // console.log('最后转化时间：', finTransferTime)
//   return finTransferTime
// }
