export function verifyTextLength(text) {
  let length = 0
  const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g
  let urlList = text.match(reg)
  if (urlList === null) {
    urlList = []
  }
  console.log('链接：', urlList)
  const dealText = text.replace(reg, '')

  length += urlList.length * 23
  console.log('替换后：', dealText)

  for (let i = 0; i < dealText.length; i++) {
    const chr = dealText.charCodeAt(i)
    // console.log(dealText.charAt(i), chr)
    if (chr < 0 || chr > 255) {
      length += 2
    } else {
      length += 1
    }
    // console.log(length)
  }
  console.log('长度：', length)
  if (length > 280) {
    return false
  } else {
    return true
  }
}
